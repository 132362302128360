@import url('https://fonts.googleapis.com/css2?family=Tajawal:wght@400;500;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: 'Tajawal', sans-serif;
}

.links::-webkit-scrollbar {
  display: none;
}

.tox-statusbar {
  display: none !important;
}

.ck-editor__editable_inline {
  min-height: 150px;
}

.truncate-mine {
  display: -webkit-box;
  min-width: 100px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}